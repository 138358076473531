

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {active} from "@/core/data/genericData";
import Quill from "quill/dist/quill.js";
import { useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "add-news",
  components: {
    //AddTagsModal
    },
   methods: {
    onChange() 
    { 
      this.formData.category_select = '' 
      }
  },

  setup() {

    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addNewsModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const loadingHsn = ref<boolean>(false);
    const router = useRouter(); 
    const shape = ref([]);
     
      
    onMounted( async () => {
      //const db_data = {}
      setCurrentPageBreadcrumbs("Sales Order Report", []);
      await setParentCatData()
      await setChildCatData()
     
    });



    const parentcat = ref([]);
     const setParentCatData = async () => {
       // loading.value = true;
    //     try {
    //       var values = {}
    //       await store.dispatch(Actions.CUST_NEWS_PARENT_CATEGORY, values).then(({ data }) => {
    //       parentcat.value = data;
    //       loading.value = false;
         
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }

    }

     const childcat = ref([]);
     const setChildCatData = async () => {
      
        //loading.value = true;
    //     try {
    //       var values = {
    //         "category_id": formData.value.parent_select
    //         }
    //       await store.dispatch(Actions.CUST_NEWS_CHILD_CATEGORY, values).then(({ data }) => {
    //       childcat.value = data;
    //       loading.value = false;
         
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);        
    //     });
        
    //   } catch (e) {
    //     console.log(e);
    //   }

    }
    
    const formData = ref({
      name: "",      
      description: "",
      short_description: "",
      parent_select: "",
      tag_select:"",    
      news_select: "",
      category_select: "",
    });    

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "Name is required",
      //     trigger: "change",  
      //     //pattern:"^[a-zA-Z]+$",        
      //   },        
      // ],    
      // description: [
      //   {
      //     required: true,
      //     message: "Description is required",
      //     trigger: "change",  
      //   }
      // ],
      // short_description: [
      //   {
      //     required: true,
      //     message: "Short Description is required",
      //     trigger: "change",  
      //   }
      // ],
      // parent_select: [
      //   {
      //     required: true,
      //     trigger: "change",  
      //   }
      // ],
      // category_select: [
      //   {
      //     required: true,
      //     trigger: "change",  
      //   }
      // ],
      // news_select: [
      //   {
      //     required: true,
      //     message: "News Type is required",
      //     trigger: "change",  
      //   }
      // ],
      //  tag_select: [
      //   {
      //     required: true,
      //     message: "Tag is required",
      //     trigger: "click",  
      //   }
      // ],
      // minutes: [
      //   {
      //     required: true,
      //     message: "Minutes to read is required",
      //     min : 0  
      //   }
      // ],
       
    });


    const setNewsData = async (data) => {
      // const db_data = {
      //   "news_id": 0,
      //   "news_title": data.name,
      //   "news_short_description": data.short_description,
      //   "category_id": data.category_select,
      //   "parent_category_id": data.parent_select,
      //   "news_type_id": data.news_select,
      //   "news_read_views":2,
      //   "is_active": data.active,
      //   }
        
      //   await store.dispatch(Actions.CUST_GET_DIN, db_data).then(({ data }) => 
      //   {
      //     if (data){
            
      //       setTimeout(() => {
      //         loading.value = false;
      //         Swal.fire({
      //           text: "News has been successfully created.",
      //           icon: "success",
      //           buttonsStyling: false,
      //           confirmButtonText: "Ok, got it!",
      //           customClass: {
      //             confirmButton: "btn btn-primary",
      //           },
      //         }).then(() => {
      //           hideModal(addNewsModalRef.value);  
      //           router.go(0)           
      //         });
      //       }, 2000)          

      //     } else {

      //       loading.value = false;

      //       Swal.fire({
      //         text: "Sorry, looks like there are some errors detected, please try again.",
      //         icon: "error",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       })  
      //       return false;
      //     }
      
      // })
      // .catch(({ response }) => {
      //   console.log(response);        
      // });

    }

   
    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setNewsData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

    };

    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addNewsModalRef,
      setParentCatData,
      setChildCatData,
      childcat,
      length,
      shape,
      parentcat,
      active_list_data,
    };
  },
});
